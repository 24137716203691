<ih-basic-dialog>
  <ng-container ih-header>Change profile image </ng-container>
  <ng-container ih-content>
    <ih-image-editor

      [formControl]="imageEditor"
      [allowStockPhotos]="false"
      [allowDelete]="false"

      placeholderSvg="account"

      [idealHeight]="256"

      [idealWidth]="256"

      [allowOriginalSize]="false"
      type="accounts"

    >
    </ih-image-editor>
  </ng-container>
  <ng-container ih-footer>
    <button mat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button cdkFocusInitial color="accent" (click)="submit()">Update</button>
  </ng-container>
</ih-basic-dialog>
