import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Inject } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BasicDialogComponent } from '@ih/basic-dialog';
import { ImageEditorComponent } from '@ih/image';
import { ImageDialogOptions } from '@ih/interfaces';

@Component({
  standalone: true,
  selector: 'ih-change-profile-image-dialog',
  templateUrl: './change-profile-image-dialog.component.html',
  styleUrls: ['./change-profile-image-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MatButtonModule, ReactiveFormsModule, BasicDialogComponent, ImageEditorComponent]
})
export class ChangeProfileImageDialogComponent {
  @HostBinding('class.ih-change-profile-image-dialog') hostClass = true;

  imageEditor = new UntypedFormControl();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ImageDialogOptions,
    private mdDialogRef: MatDialogRef<ChangeProfileImageDialogComponent>
  ) {
    this.imageEditor.setValue(data.image);
  }

  cancel(): void {
    this.mdDialogRef.close();
  }

  submit(): void {
    this.mdDialogRef.close(JSON.parse(JSON.stringify(this.imageEditor.value)));
  }
}
